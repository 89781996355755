import React, { Component, Fragment } from 'react';
import renderHTML from 'react-render-html';
import Button from './Button';
import QuantityBar from './QuantityBar';
import { connect } from 'react-redux';
import { addToCart } from './../actions/cartActions';
import { fetchProductOptions } from './../actions/productAction';
import { syncQuantity } from './../actions/catalogActions';
import NumberFormat from 'react-number-format';
import store from './../store';
import { generateBreadcrumbs, parseFreeAccessories, parseFreeAccessoriesForView, limitChar } from '../helpers/utility';
import { IMAGE_BASE_URL, productDetailBreadcrumb, productDetailWithoutCategoryBreadcrumb } from '../helpers/constants';
import { TO_PRODUCT, TO_HOME, TO_CATEGORY, TO_HOW_TO_ORDER } from '../helpers/routesConstants';
import { Link } from 'react-router-dom';
import Dropdown from "./dropdown";
import { FETCH_PRODUCT_OPTIONS } from '../helpers/actionConstants';
import DocumentMeta from 'react-document-meta';
import Axios from 'axios';
import SliderImage from "react-zoom-slider";
import debounce from 'lodash/debounce';
import Skeleton from 'react-loading-skeleton'
import { seoData } from './../actions/globalActions';

const FreeAccessories = ({ freeAccessories }) => (
    <div className="row margin-top-14">
        <div className="col-sm-12">
            <p className="h6">The following items are included with this product:</p>
            <ul className="nav nav-pills flex-column text-sm category-menu side-bar-ul">
                {freeAccessories.map(accessory => (
                    <li key={accessory.id} className="nav-item">
                        {accessory.Quantity} of <Link title={accessory.ProductName} target="_blank" to={TO_PRODUCT + accessory.ProductCode.toLowerCase()}>{limitChar(accessory.ProductName, 50)}</Link>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productOptions: [],
            products: [],
            options: [],
            selectedOptions: {},
            freeAccessories: [],
            meta:{},
            imagesCheck: {},
            imageArrayWithSizes: [],
            dataSet: false,
            imageRejected: false,
            functionCompleted: false,
            dontCallAgain: false,
            allProductCategory: null,
            selectedCategory: null,
            storeCategory: null,
            selectedCategoryDetail: null,
            isLoading:true,

        }
        this.calculateImageSizeDebounced = debounce(this.calculateImageSize, 10);
    }
    _quantityUpdate = (updatedQuantity) => {
        var product = this.state.product;
        product.Quantity = updatedQuantity;
        this.setState({ product: product });
        this.setState({
            Quantity: updatedQuantity
        })
    }
    _addToCart = (product) => {
        product.Quantity = 1;
        // product.Childs = parseFreeAccessories(product, this.props.products);
        product.Childs = parseFreeAccessories(product, this.props.products);

        product.SelectedOptions = this.state.selectedOptions
        // for (const key in product.SelectedOptions) {
        //     if (product.SelectedOptions[key].ProductPrice > 0) {
        //         product.ProductPrice += product.SelectedOptions[key].ProductPrice
        //     }
        // }
        const itemDetails = product;
        this.setState({
            Quantity: 1
        })

        const syncCatalog = {
            ProductCode: product.ProductCode,
            Quantity: product.Quantity
        }
        store.dispatch(addToCart(itemDetails));
        var items = JSON.parse(localStorage.getItem('cartStatePersist')) || []
        items.push(itemDetails)
        localStorage.setItem('cartStatePersist', JSON.stringify(items))
        store.dispatch(syncQuantity(syncCatalog));
    };
    imageExists = (image) => {
        Axios.get(`${IMAGE_BASE_URL}${image.ImageURL}.${image.ext}`)
        .then((response) => {
            if(response.data.includes("Page not found")) {
                const imgCheck = this.state.imagesCheck
                const src = "/assets/img/no-image.gif"
                imgCheck[image.ID] = {
                    isImg: false,
                }
                this.setState({...this.state, imagesCheck: imgCheck})
                return false
            } else {
                const imgCheck = this.state.imagesCheck
                const src = `${IMAGE_BASE_URL}${image.ImageURL}.${image.ext}`
                imgCheck[image.ID] = {
                    isImg: true,
                }
                this.setState({...this.state, imagesCheck: imgCheck})
                console.log("Image Found");
                return true
            }
        })
        .catch((error) => {
            const imgCheck = this.state.imagesCheck
            const src = "/assets/img/no-image.gif"
            imgCheck[image.ID] = {
                isImg: false,
            }
            this.setState({...this.state, imagesCheck: imgCheck})
            return false
        })
    }
    getSelectedOptions(selectedOptions) {
        this.setState({ selectedOptions }, () => {
            // console.log(this.state.selectedOptions,"yoo");
        })
    }
    componentDidMount() {
        this.setState({ isLoading: true }); 

        const { categories, productCode } = this.props;
        const allProductsCategory = this.props.categories.filter((item)=> item.name === 'All Products');
        this.setState({ productCode: this.props.productCode.indexOf('.htm') > -1 ? this.props.productCode.split('.')[0] : this.props.productCode });
        const product = this.props.products.find(item => item.ProductCode.toLowerCase() === productCode.toLowerCase());

        if (product) {
            this.setState({ isLoading: false });

            // Set store category from product details
            const storeCategory = product.ProductCategories || (allProductsCategory[0] ? allProductsCategory[0].id : null);
            this.setState({ productCode: product.ProductCode, storeCategory });
            // Find and set selected category detail from categories
            const selectedCategory = categories.find(category => category.id === storeCategory);
            if (selectedCategory) {
                this.setState({ selectedCategory });
                // Optionally, you can store more details of selected category if needed
                this.setState({ selectedCategoryDetail: selectedCategory });
            }
            // Fetch product options and other necessary data
            store.dispatch(fetchProductOptions(product.OptionIDs))

            this.setState({ isLoading: false });
        } else {
            this.setState({ isLoading: false });
        }
        this.setState({ options: this.props.options, allProductCategory: allProductsCategory });

        window.check = true;
    }

    componentWillUnmount() {
        store.dispatch(seoData({}));
    }
    componentDidUpdate(prevProps) {

        if (prevProps.ip !== store.getState().customer.ip) {
            //store.dispatch(checkAccess(store.getState().customer.ip));
        }
        if (prevProps.products.length === 0 && this.props.products.length > 0) {

            this.setState({ isLoading: true }); 

            this.props.products.forEach(item => {
                if (item.ProductCode.toLowerCase() === this.state.productCode.toLowerCase()) {
                    let meta = this.state.meta
                    meta = {
                        title: item.ProductName,
                        meta: {
                            name: {
                                keywords: item.METATAG_Keywords
                            }
                        }
                    };
                    const imageFound = item.ProductImages.length > 0 && item.ProductImages[0] ? item.ProductImages[0] : '';
                    item.METATAG_Description && item.METATAG_Description && store.dispatch(seoData({
                        title: item.ProductName, 
                        description : item.METATAG_Description,
                        imageURL : `${IMAGE_BASE_URL}${imageFound.ImageURL}.${imageFound.ext}`
                    }));
                    this.setState({meta, freeAccessories: parseFreeAccessoriesForView(item, this.props.products) });
                    this.setState({ isLoading: false });

                    store.dispatch({
                        type: FETCH_PRODUCT_OPTIONS,
                        payload: []
                    });
                    const { categories } = this.props;
                    const allProductsCategory = categories.filter(cat => cat.name === 'All Products');
                    this.setState({ allProductCategory: allProductsCategory });
                    if (item) {
                        // Set store category from item details
                        const storeCategory = item.ProductCategories || this.state.storeCategory || (allProductsCategory[0] ? allProductsCategory[0].id : null);
                        this.setState({ productCode: item.ProductCode, storeCategory });

                        // Find and set selected category detail from categories
                        const selectedCategory = categories.find(category => category.id === storeCategory);
                        if (selectedCategory) {
                            this.setState({ selectedCategory });

                            // Optionally, you can store more details of selected category if needed
                            this.setState({ selectedCategoryDetail: selectedCategory });
                        }
                    }
                    this.setState({ product: item }, () => {
                        document.title = this.state.product.ProductName
                        this.state.product.ProductImages.forEach((img) => {
                            this.imageExists(img)
                        })
                        store.dispatch(fetchProductOptions(this.state.product.OptionIDs));
                    });

                }
            })
            this.state.product && this.state.product.ProductImages.length !==0 && this.calculateImageSizeDebounced();

        } else if (prevProps.products.length > 0 && this.props.products.length > 0) {

            this.props.products.forEach((item, index) => {
                if (item.ProductCode.toLowerCase() === this.state.productCode.toLowerCase()) {
                    let meta = this.state.meta
                        meta = {
                            title: item.ProductName,
                            meta: {
                                name: {
                                    keywords: item.METATAG_Keywords
                                }
                            }
                    };
                    if (prevProps.products[index].Quantity !== item.Quantity) {  
                      
                        store.dispatch({
                            type: FETCH_PRODUCT_OPTIONS,
                            payload: []
                        });
                        this.setState({ meta, product: item }, () => {
                            document.title = this.state.product.ProductName
                            this.state.product.ProductImages.forEach((img) => {
                                this.imageExists(img)
                            })
                            store.dispatch(fetchProductOptions(this.state.product.OptionIDs));

                        });
                        this.setState({ freeAccessories: parseFreeAccessoriesForView(item, this.props.products) });
                    }
                    if (meta.title !== this.state.meta.title) {
                        const imageFound = item.ProductImages.length > 0 && item.ProductImages[0] ? item.ProductImages[0] : '';
                        item.METATAG_Description && item.METATAG_Description && store.dispatch(seoData({
                            title: item.ProductName, 
                            description : item.METATAG_Description,
                            imageURL : `${IMAGE_BASE_URL}${imageFound.ImageURL}.${imageFound.ext}`
                        }));
                        this.setState({ meta });
                    }
                }
            })
            this.state.product && this.state.product.ProductImages.length !==0 && this.calculateImageSizeDebounced();
        }
        if (this.state.product === undefined && this.props.products.length > 0) {
            this.props.products.forEach(elem => {
                if (elem.ProductCode.toLowerCase() === this.props.productCode.toLowerCase()) {
                    store.dispatch({
                        type: FETCH_PRODUCT_OPTIONS,
                        payload: []
                    });
                    this.setState({ product: elem }, () => {
                        document.title = this.state.product.ProductName
                        this.state.product.ProductImages.forEach((img) => {
                            this.imageExists(img)
                        })
                        store.dispatch(fetchProductOptions(this.state.product.OptionIDs));
                        this.setState({ isLoading: false }); 

                    });
                    this.setState({ freeAccessories: parseFreeAccessoriesForView(elem, this.props.products) });
                }
            });
            this.state.product && this.state.product.ProductImages.length !==0 && this.calculateImageSizeDebounced();
        }
        let productIndex = this.props.products.findIndex(i => i.ProductCode.toLowerCase() === this.state.productCode.toLowerCase());
        if (this.state.product !== undefined && this.props.products.length > 0 && this.state.product.Quantity !== this.props.products[productIndex].Quantity) {

            store.dispatch({
                type: FETCH_PRODUCT_OPTIONS,
                payload: []
            });
            this.setState({ product: this.props.product }, () => {
                document.title = this.state.product.ProductName
                this.props.product.ProductImages.forEach((img) => {
                    this.imageExists(img)
                })
                store.dispatch(fetchProductOptions(this.state.product.OptionIDs));
                this.setState({ isLoading: false });


            });
            this.state.product && this.state.product.ProductImages.length !==0 && this.calculateImageSizeDebounced();
        }
        if (JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
            this.setState({ options: this.props.options });
        }


    }

    async loadImage(imageURL) {
        return new Promise((resolve) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = imageURL;
            img.onload = () => resolve(img);
            img.onerror = (error) => {
                console.error('Error loading image rej:', error);
                this.setState({imageArrayWithSizes: [],dontCallAgain: true})
            };
        });
    }
    

    async calculateImageSize() {
        const {product} = this.state;
        const newProductImagesArray = product && product.ProductImages.length !== 0 && product.ProductImages.map(async (item, index) => {
            const imageURL = `${IMAGE_BASE_URL}${item.ImageURL}.${item.ext}`;
                try {
                    const loadedImage = !this.state.dontCallAgain && await this.loadImage(imageURL);
                    let imageSize = {
                        width: loadedImage.naturalWidth,
                        height: loadedImage.naturalHeight,
                    };
            
                    const minWidth = 1000;
                    const minHeight = 1000;
            
                    if (imageSize.width < minWidth || imageSize.height < minHeight) {
                        const aspectRatio = loadedImage.naturalWidth / loadedImage.naturalHeight;
            
                        if (aspectRatio >= 1) {
                            imageSize = {
                                width: Math.max(imageSize.width, minWidth),
                                height: Math.max(imageSize.width / aspectRatio, minHeight),
                            };
                        } else {
                            imageSize = {
                                width: Math.max(imageSize.height * aspectRatio, minWidth),
                                height: Math.max(imageSize.height, minHeight),
                            };
                        }
            
                        const canvas = document.createElement('canvas');
                        canvas.width = imageSize.width;
                        canvas.height = imageSize.height;
                        const context = canvas.getContext('2d');
                        context.drawImage(loadedImage, 0, 0, imageSize.width, imageSize.height);
            
                        const modifiedImageURL = canvas.toDataURL();
                        return {
                            image: modifiedImageURL,
                            id: item.ID,
                            size: imageSize,
                        };
                    } else {
                        return {
                            image: imageURL,
                            id: item.ID,
                            size: imageSize,
                        };
                    }
                } catch (error) {
                    console.error('Error loading image:', error);
                    this.setState({imageArrayWithSizes: [], dontCallAgain: true})
                    return null;
                }
        });

        const imageArray = await Promise.all(newProductImagesArray);
        if(imageArray.length > 0 && this.state.dataSet === false){
            this.setState({
                dataSet: true,
                imageArrayWithSizes: imageArray,
                functionCompleted: true,
            });
        }

    }
    render() {
        const { selectedCategory, storeCategory, selectedCategoryDetail } = this.state;
        const product = this.state.product ? this.state.product : { ProductImages: [] };
        var ProductCategories = product.CategoryIDs ? product.CategoryIDs.split(',') : [];
        let selectedCategories = ProductCategories.map(elem => {
            return this.props.categories.filter(category => {
                return category.id == elem
            })
        })
        selectedCategories = [].concat.apply([], selectedCategories);
        let finalCategories = selectedCategories.filter(selectedCategory => {
            return selectedCategory.id !== 1829
        })

        var cartAccess = this.props.global.cartAccess;
        var cartCounty = this.props.global.cartCounty;
        
        product.TechSpecs = product.TechSpecs ? product.TechSpecs.replace(/â€¢/g, '•').replace(/<table/g, '<div class="table-responsive"><table').replace(/<\/table>/g, '</table></div>') : undefined;
        product.ProductDescription = product.ProductDescription ? product.ProductDescription.replace(/<table/g, '<div class="table-responsive"><table').replace(/<\/table>/g, '</table></div>') : undefined;
        var productPrice = product.ProductPrice - ((product.ProductPrice / 100) * product.Discount);
        return (
            <DocumentMeta {...this.state.meta}>
            <div className={`col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12 product-detail ${product.ProductCode}`}>
                {/* <p className="goToDescription"><a href="#details" className="scroll-to text-uppercase">Scroll to product details, specification & manuals</a></p> */}
                {(product.ProductCode &&
                    <div>
                        <div id="productMain" className="row padding-left-right">
                            {selectedCategoryDetail && selectedCategoryDetail.name && storeCategory ? (
                                <p className="product-map-arrow">{generateBreadcrumbs(productDetailBreadcrumb(selectedCategoryDetail, product.ProductCode,  storeCategory))}</p>
                            ): (
                                <p className="product-map-arrow">{generateBreadcrumbs(productDetailWithoutCategoryBreadcrumb(product.ProductCode))}</p>
                            )}
                            <div className="col-sm-12 mt-3 panel-heading">
                                <h1 className="uppercase h4 top-product-name">{product.ProductName}</h1>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="hide-mobile">
                                    {!this.state.dontCallAgain &&  this.state.imageArrayWithSizes.length > 0 && product && product.ProductImages ? ( 
                                        <SliderImage
                                        data={this.state.imageArrayWithSizes}
                                        width="100%"
                                        />         
                                    ) : product.ProductImages.length === 0 ? (
                                        product.ProductPhotoURL?
                                        <SliderImage  
                                            data={[  { image: IMAGE_BASE_URL + product.ProductPhotoURL,id: 'product-photo',alt: 'Product Image',size: '100%',}, ]}
                                            width="100%"
                                        />
                                        :
                                        <div data-slider-id="1" className="owl-carousel w-100 shop-detail-carousel hide-mobile">
                                            <img className="img-fluid" src="/assets/img/no-image.gif" alt="Product Image"/>
                                        </div>
                                    ): (
                                        <div className="skeleton-container">
                                            <Skeleton height="390px" />
                                        </div>
                                    )}
                                </div>

                                <div className="hide-desktop">   
                                    <div data-slider-id="1" className="owl-carousel shop-detail-carousel">
                                    {product.ProductImages && product.ProductImages.length !== 0 ? (
                                        product.ProductImages.map((image) => {
                                            // if (image.IsThumb === 0)
                                            return (
                                                <img
                                                    key={image.ID}
                                                    src={`${IMAGE_BASE_URL}${image.ImageURL}.${image.ext}`}
                                                    alt="Product Image"
                                                    className="img-fluid"
                                                ></img>
                                            );
                                        })
                                    ) : (
                                        product.ProductPhotoURL? 
                                      <div data-slider-id="1" className="owl-carousel w-100 shop-detail-carousel">
                                           <img src={IMAGE_BASE_URL + product.ProductPhotoURL} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/img/no-image.gif"}} 
                                                alt="Product Image" className="img-fluid"/>
                                        </div>  
                                        :
                                        <div data-slider-id="1" className="owl-carousel w-100 shop-detail-carousel">
                                            <img className="img-fluid" src="/assets/img/no-image.gif" alt="Product Image" />
                                        </div>
                                    )}
                                </div>
                                    <div data-slider-id="1" className="owl-thumbs">
                                        {product.ProductImages.map(image => {
                                            // if (image.IsThumb === 1)
                                            return <button key={image.ID} className="owl-thumb-item"><img src={`${IMAGE_BASE_URL}${image.ImageURL}.${image.ext}`} onError={(e) => { e.target.onerror = null; e.target.src = "/assets/img/no-image.gif" }} alt="" className="img-fluid slider-thumb img-thumb"></img></button>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="box">
                                    {(product.CategoryIDs && (productPrice !== 0 || product.IsFreeProduct !== 0)) ?
                                        <Fragment>
                                            {/* <h6> </h6> */}
                                            {/* <p className="lighter-note"><strong>Availability: </strong>{renderHTML(product.Availability || '')}</p> */}
                                            <div className="above-pricing">
                                                <p className="lighter-note">{renderHTML(product.ProductDescription_AbovePricing || '')}</p>
                                            </div>
                                        </Fragment> : ''}
                                        {this.state.isLoading? (<div className='priceloader'> <Skeleton/></div> ) : (
                                                      product.CategoryIDs && product.HideProduct !== 'Y' && cartAccess && productPrice !== 0 ? (
                                                          <p className="price">
                                                              <NumberFormat 
                                                                  isNumericString={true} 
                                                                  decimalScale={2} 
                                                                  value={(product.ProductPrice - ((product.ProductPrice / 100) * product.Discount)).toFixed(2)} 
                                                                  displayType={'text'} 
                                                                  thousandSeparator={true} 
                                                                  prefix={'$'} 
                                                              />
                                                          </p>
                                                      ) : null
                                                  )}
                                                  
                                  
                                    <p className="lighter-note">
                                        Product Code: {product.ProductCode}
                                    </p>
                                    {/* {product.CategoryIDs && product.HideProduct !== 'Y' && productPrice !== 0 ?
                                        (<div>
                                            {(product.Quantity === 0 || product.Quantity === undefined) ? (cartAccess === true ? (<Button onClick={() => { this._addToCart(product) }} />) : (<Button disabled="true" />)) : <QuantityBar callback={this._quantityUpdate} product={product} />}
                                        </div>) : ''
                                    }
                                    { this.props.options.length > 0  && <p className="lighter-note">Chose your options before add to cart</p>} */}
                                    {(this.state.freeAccessories.length > 0 && <FreeAccessories freeAccessories={this.state.freeAccessories} />)}
                                    {this.props.options.length > 0 && <Dropdown options={this.props.options} detailRef={this} />}
                                    {/* { this.props.options.length > 0  && <strong className="lighter-note text-danger">*Chose your options before add to cart</strong>} */}
                                    {(product.CategoryIDs && product.HideProduct !== 'Y' && (productPrice !== 0 || product.IsFreeProduct !== 0)) ?
                                        (<div>
                                            {
                                            // (product.Quantity == 0 || product.Quantity === undefined || product.Quantity === null || product.Quantity === '') ?
                                                (cartAccess === true ?
                                                    (<div><Button onClick={() => { this._addToCart(product) }} /> <Button disabled="true" /></div>)
                                                :
                                                    (<div className='d-block'><Button disabled="true" className='d-block'/> <a  className='d-block' href='/how-to-order'>Why I can't see prices</a></div>))
                                            // : 
                                                // <QuantityBar callback={this._quantityUpdate} product={product} />
                                            }
                                        </div>) : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <h2 id="details" className="lead short-description pt-3">{renderHTML(product.ProductDescriptionShort || '')}</h2>
                        <div className="pills-head">
                            <ul id="pills-tab" role="tablist" className="nav nav-pills nav-justified">
                                <li className="nav-item ">
                                    <a id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true" className="nav-link active"> Description</a>
                                </li>
                                <li className="nav-item ">
                                    <a id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false" className="nav-link"> Specifications</a>
                                </li>
                                <li className="nav-item ">
                                    <a id="pills-contact-tab" data-bs-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false" className="nav-link"> Manuals</a>
                                </li>
                                <li className="nav-item ">
                                    <a id="pills-export-tab" data-bs-toggle="pill" href="#pills-export" role="tab" aria-controls="pills-export" aria-selected="false" className="nav-link"> Compliance </a>
                                </li>
                            </ul>
                            <div id="pills-tabContent" className="tab-content">
                                <div id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" className="tab-pane fade show active product-description">{renderHTML(product.ProductDescription || 'No Description Provided')}</div>
                                <div id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" className="tab-pane fade tech-spec">{renderHTML(product.TechSpecs || 'No Specification Provided')}</div>
                                <div id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" className="tab-pane fade detail-url">{renderHTML(product.ProductDetailURL || 'No Manuals Provided')}</div>
                                <div id="pills-export" role="tabpanel" aria-labelledby="pills-export-tab" className="tab-pane fade export-url" >
                                {product.HarmonizedCode && product.HarmonizedCode !== "undefined" && product.HarmonizedCode !== null &&
                                <div>
                                <b> Harmonized Code</b>: {product.HarmonizedCode}
                                </div>
                                }
                                {product.ExportControlClassificationNumber && product.ExportControlClassificationNumber!=="undefined" && product.ExportControlClassificationNumber!==null &&
                                <div>
                                <b> ECCN</b>: {product.ExportControlClassificationNumber}
                                </div>
                                }
                                {product.UnitOfMeasure && product.UnitOfMeasure!=="undefined" && product.UnitOfMeasure!== null &&
                                <div>
                                <b> Unit Of Measure</b>: {product.UnitOfMeasure} 
                                </div>
                                }
                                {product.CountryOfOrigin && product.CountryOfOrigin!=="undefined" && product.CountryOfOrigin!==null && 
                                <div>
                                <b> Country of Origin</b>: {product.CountryOfOrigin} 
                                </div>
                                }
                                    
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
            </div>
            </DocumentMeta>
        );
    }
}
const stateMapProductDetail = (state) => {
    return {
        products: state.product.products,
        options: state.product.options,
        cartItems: state.cart,
        global: state.global,
        categories: state.category.categories,
        ip: state.customer.ip
    };
};

export default connect(stateMapProductDetail)(ProductDetail);
