import React, { Component,Fragment } from 'react';
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux';
import { TO_CATEGORY, TO_RETURN, TO_SHIPPING, TO_FAQ, TO_ABOUTUS, TO_HOW_TO_ORDER, TO_TERM_AND_CONDITION } from '../helpers/routesConstants';
import Loader from "./Loader";
import Accordion from 'react-bootstrap/Accordion'
const CategoryTemp = ({ categories, onSelectCategory }) => (
    <Fragment>
        <ul className="nav nav-pills flex-column text-sm category-menu side-bar-ul hide-mobile">
            {categories.map(category => (
                <li key={category.id} className="nav-item">
                    <NavLink  to={TO_CATEGORY+category.id} className="nav-link d-flex align-items-center justify-content-between"   onClick={() => onSelectCategory(category)}>
                        <span  style={{marginLeft: "10px"}}> {category.name}  </span>
                        <span className="badge badge-light">{category.totalProducts}</span>
                    </NavLink>
                </li>
            ))}
        </ul>
        <ul className="text-sm category-menu side-bar-ul new-nav-scrollable show-mobile hide-desktop">
            {categories.map(category => (
                <li key={category.id} className="nav-item">
                    <NavLink onClick={() => onSelectCategory(category)} activeClassName='active' to={TO_CATEGORY+category.id} className="nav-link d-flex align-items-center justify-content-between"  style={{fontSize: 'xx-small', margin: 'unset', marginRight: '15px'}}>
                        <span   style={{marginLeft: "10px"}}> {category.name}  </span>
                    </NavLink>
                </li>
            ))}
        </ul>
        <hr/>
    </Fragment>
);

const MoreInfo = () => (
    <ul className="nav nav-pills flex-column text-sm category-menu side-bar-ul">
        <li key="1890" className="nav-item">
            <NavLink activeClassName='active' to={TO_HOW_TO_ORDER} className="nav-link d-flex align-items-center justify-content-between">
                <span style={{marginLeft: "10px"}}> How to Order? </span>
            </NavLink>
        </li> 
        <li key="1891" className="nav-item">
            <NavLink activeClassName='active' to={TO_RETURN} className="nav-link d-flex align-items-center justify-content-between">
                <span   style={{marginLeft: "10px"}}> RETURNS  </span>
            </NavLink>
        </li>
        <li key="1892" className="nav-item">
            <NavLink activeClassName='active' to={TO_SHIPPING} className="nav-link d-flex align-items-center justify-content-between">
                <span  style={{marginLeft: "10px"}}> Shipping  </span>
            </NavLink>
        </li>
        <li key="1893" className="nav-item">
            <NavLink activeClassName='active' to={TO_FAQ} className="nav-link d-flex align-items-center justify-content-between">
                <span  style={{marginLeft: "10px"}}> FAQ  </span>
            </NavLink>
        </li>
        <li key="1894" className="nav-item">
            <NavLink activeClassName='active' to={TO_TERM_AND_CONDITION} className="nav-link d-flex align-items-center justify-content-between">
                <span  style={{marginLeft: "10px"}}> Terms And Conditions  </span>
            </NavLink>
        </li>
        <li key="1895" className="nav-item">
            <NavLink activeClassName='active' to={TO_ABOUTUS} className="nav-link d-flex align-items-center justify-content-between">
                <span  style={{marginLeft: "10px"}}> About Us  </span>
            </NavLink>
        </li>
    </ul>
);

class SideBar extends Component {
    constructor(){
        super()
        this.state = { showUp: false };
    }
    handleSelectCategory = (selectedCategory) => {
        localStorage.setItem('selectedCategory', JSON.stringify(selectedCategory));
    };  
    render() {
        return (
            
            <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {this.props.categories && this.props.categories.length === 0 && <Loader />}

                {(this.props.categories.length > 0 && 
                <Fragment>
                    <div className="panel panel-default sidebar-menu panel-sidebar navbar-expand-lg">
                        <div className="panel-heading hide-mobile"  style={{marginLeft: "10px"}}>
                            <div>
                                <h2 className="h4 panel-title">PRODUCTS</h2>
                            </div>
                            <div>
                                <button onClick={() => {this.setState({showUp: !this.state.showUp})}} type="button" data-bs-toggle="collapse" data-target="#navigation-sidebar-top" className="navbar-toggler btn-template-outlined sidebar-button"><span className="sr-only">Toggle navigation</span><i className={this.state.showUp ? "fa fa-chevron-up" : "fa fa-chevron-down"}></i></button>
                            </div>
                        </div>
                        <div id="navigation-sidebar-top" className="panel-body sidebar-border navbar-collapse collapse hide-mobile">
                            <CategoryTemp categories={this.props.categories} onSelectCategory={this.handleSelectCategory}/>
                        </div>
                        <div id="navigation-sidebar-top" className="panel-body sidebar-border new-nav-scrollable hide-desktop">
                            <CategoryTemp categories={this.props.categories} onSelectCategory={this.handleSelectCategory}/>
                        </div>
                    </div>
                    <div className="d-sm-block d-none panel panel-default sidebar-menu panel-sidebar navbar-expand-lg hide-mobile">
                        <div className="panel-heading " style={{marginLeft: "10px", marginTop: "10px"}}>
                            <div>
                                <h2 className="h4 panel-title">MORE INFO</h2>
                            </div>
                            <div>
                                <button type="button" data-bs-toggle="collapse" data-target="#navigation-sidebar-more" className="navbar-toggler btn-template-outlined sidebar-button"><span className="sr-only">Toggle navigation</span><i className="fa fa-chevron-down"></i></button>
                            </div>
                        </div>  
                        <div id="navigation-sidebar-more" className="panel-body hide-mobile navbar-collapse collapse">
                            <MoreInfo />
                        </div>
                        <div id="navigation-sidebar-more" className="panel-body sidebar-border hide-mobile hide-desktop">
                            <MoreInfo />
                        </div>
                    </div>
                </Fragment>
                )}
            </div>
        );
    }
}


const stateMapSideBar = (state) => {
    return {
        categories: state.category.categories,
    };
};

export default connect(stateMapSideBar)(SideBar);
