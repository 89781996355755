import React, { Component, Fragment } from 'react';
// import { fetchProducts } from '../actions/productAction';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { TO_STORE, TO_CART, TO_CUSTOMER_ACCOUNT, TO_HOME, 
    TO_LOGIN, TO_FAQ, TO_HOW_TO_ORDER, TO_CATEGORY, TO_RETURN, TO_SHIPPING, TO_ABOUTUS, TO_TERM_AND_CONDITION, TO_PRODUCT, TO_REGISTER } from "../helpers/routesConstants"
import LogoutButton from './logout-button';
import MetaTags from 'react-meta-tags';
import {limitChar} from '../helpers/utility';
import Search from '../components/search';
import { searchProductsFromHeader } from '../actions/productAction';
import store from '../store';

const CategoryTemp = ({ categories }) => {
    return (
    <ul className="nav nav-pills flex-column text-sm category-menu side-bar-ul">
        {categories.map(category => (
            <li key={category.id} className="nav-item">
                <NavLink activeClassName='active' to={TO_CATEGORY+category.id} className="nav-link d-flex align-items-center justify-content-between">
                    <span style={{color:'white'}}> {category.name}  </span>
                    <span className="badge badge-light">{category.totalProducts}</span>
                </NavLink>
            </li>
        ))}
    </ul>
);}
class Header extends Component {
    constructor(props) {
        super(props);
        this.showhandle = this.showhandle.bind(this);
        // this.hidehandle = this.hidehandle.bind(this);
        this.state = {
            selectedCategoryId: null,
            collapsedProducts: [],
            show: false,
            filteredProducts: [],
            displayedProducts: 5,
            onSearchValue: null,
            isMoreInfoOpen: false,
            dropdownVisible: false,
            isMobile: false
        }
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        document.addEventListener('click', this.handleClickOutside, true);
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        window.removeEventListener('resize', this.handleResize);
    }
    
    handleClickOutside(event) {
        const dropdownContainer = document.querySelector('.search-dropdown-container');
        if (dropdownContainer && !dropdownContainer.contains(event.target)) {
          // Clicked outside the dropdown, close it
            this.setState({ dropdownVisible: false });
        }
    }
    handleResize() {
        this.setState({
          isMobile: window.innerWidth <= 1199
        });
    }
    showhandle(val){
        this.setState({
            
            show: val
        })
    }

    handleChange = (event) => {
        const {products} = this.props;
        const {value} = event.target;

        if(!value){
            this.setState({
                filteredProducts: [],
                dropdownVisible: false,
            });
            return;
        }
        const inputValue = value.toLowerCase();
        const filteredProducts = products.filter(product =>
            product.HideProduct !== 'Y' && product.ProductName.toLowerCase().includes(inputValue)
        );

        this.setState({
            inputValue,
            filteredProducts,
            displayedProducts: 5,
            onSearchValue:  value,
            dropdownVisible: true,
        });
    }

    handleLoadMore = () => {
        this.setState((prevState) => ({
            displayedProducts: prevState.displayedProducts + 5,
        }));
    }

    buttonClick =()=>{
        const {onSearchValue} = this.state;
        store.dispatch(searchProductsFromHeader(onSearchValue));
        if(onSearchValue){
            this.setState({
                filteredProducts: [],
            });
        }
    }

    handleCollapsedProductNavigation = (product) => {
        const productCode = product.ProductCode.toLowerCase();
        
        const link = document.createElement('a');
        link.href = `${TO_PRODUCT}${productCode}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
        this.showhandle(false)      
    };

    toggleMoreInfo = () => {
        this.setState(prevState => ({
          isMoreInfoOpen: !prevState.isMoreInfoOpen,
        }));
      };
      handleSelectCategory = (selectedCategory) => {
        localStorage.setItem('selectedCategory', JSON.stringify(selectedCategory));
    };
    render() {
        const menuItems = [
            // {
            //   key: 1890,
            //   to: TO_PRICES_CART_FAQ_ANSWER,
            //   label: "Can't See Prices or Add Items into Cart?",
            // },
            {
              key: 1890,
              to: TO_HOW_TO_ORDER,
              label: "How to Order?",
            },
            {
              key: 1891,
              to: TO_RETURN,
              label: "RETURNS",
            },
            {
              key: 1892,
              to: TO_SHIPPING,
              label: "Shipping",
            },
            {
              key: 1893,
              to: TO_FAQ,
              label: "FAQ",
            },
            {
              key: 1894,
              to: TO_TERM_AND_CONDITION,
              label: "Terms And Conditions",
            },
            {
              key: 1895,
              to: TO_ABOUTUS,
              label: "About Us",
            },
          ];
        const {filteredProducts, displayedProducts, isMoreInfoOpen, dropdownVisible, isMobile} = this.state;
        const visibleProducts = filteredProducts.slice(0, displayedProducts);
        const title = this.props.seoData && this.props.seoData.title ? this.props.seoData.title : "Intrepid Control Systems";
        const description = this.props.seoData && this.props.seoData.description ? this.props.seoData.description : "Intrepid Control Systems is a privately-held company located in suburban Detroit, Michigan. Intrepid focuses on providing high technology computer software and hardware services and products.";
        const imageURL = this.props.seoData && this.props.seoData.imageURL ? this.props.seoData.imageURL : '';
        return (
            <Fragment>
            <div style={{position: "sticky", top: "0px", zIndex: 9}}>
                <MetaTags>
                    <title>Intrepid Control Systems</title>
                    <meta name="description"  content={description} />
                    <meta property="og:title" content={title}/>
                    <meta property="og:image" content={imageURL} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://store.intrepidcs.com/" />
                    <meta property="og:description"  content={description} />
                </MetaTags>
                {this.props.regionFaild && <div className="alert alert-danger" style={{ marginBottom: 0 }} role="alert">
                    <button type="button" className="close" data-dismiss="alert">×</button>
                    <strong>Not seeing prices?</strong> Learn how to order! <Link to={TO_HOW_TO_ORDER}>More info</Link>
                </div>}
                <div className="top-bar">
                    <div className="container" style={{paddingRight: "0px", paddingLeft: "0px"}}>
                        <div className="d-flex align-items-center justify-content-between pr-2">
                            <div className='d-flex mr-2'>
                                <button type="button" className="navbar-toggler btn-template-outlined btn-menu-outline" onClick={() => this.showhandle(true)} aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <i className="fa fa-align-justify"></i>
                                </button>
                                {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-target="#exampleModal">
                                    Launch
                                </button> */}
                                <Link to={TO_HOME} className=" home hide-desktop text-white">
                                    <img src="/assets/img/mobile_icon3.png" alt="Intrepid logo" className="d-md-inline-block logo-img-mobile" />
                                    {/* <span>IntrepidCS</span> */}
                                </Link>
                            </div>
                            <div className="col-md-8 hide-mobile">
                                <div className="row">
                                    <div className="col-md-3 p-0"><p><a className="mainStore" href="https://www.intrepidcs.com">Return to Main Site</a></p></div>
                                    <div className="col-md-3 p-0 "><p style={{color:'#fff'}}>PHONE: +1-800-859-6265</p></div>
                                    <div className="col-md-3 p-0"><p  style={{color:'#fff'}}>FAX: +1 (586) 731-2274</p></div>
                                    <div className="col-md-3 p-0 "> <a className='quoteLink' href="https://intrepidcs.com/sales" >Get a Quote</a></div>
                                    {/* <div className="col-md-3 p-0" style={{ marginTop: "-2.5px" }}><a className="txt-white" style={{ fontSize: "0.75rem" }} href="mailto:icscontactus@intrepidcs.com?Subject=Customer%20Query" target="_top">E-Mail: icscontactus@intrepidcs.com</a></div> */}
                                </div>
                            </div>
                            {/* <div className=" d-md-none">
                                <p>Intrepid Control Systems</p>
                            </div> */}
                            <div className="">
                                <div className="d-flex align-items-start justify-content-md-end justify-content-end">
                                {this.props.isLoggedIn && (
                                    <div className='login'>
                                        <Link title="My Account" to={TO_CUSTOMER_ACCOUNT} className="signup-btn"><i className="fa icon-small fa-user "></i></Link>
                                    </div>
                                )}
                                    {!this.props.isLoggedIn && (
                                        <div>
                                        {/* <div className="login hide-desktop">
                                            <Link title="Sign Up" to={TO_LOGIN} className="signup-btn "><i className="fa icon-small fa-user-o"></i><span className="d-none d-md-inline-block">Sign Up</span></Link>
                                        </div> */}
                                         <div className="login ">
                                         <Link title="Sign Up" to={TO_REGISTER} className="signup-btn "><i className="fa icon-small fa-user-o "></i>
                                         <span className="hide-mobile ">Sign Up</span></Link>
                                        </div>
                                     </div>
                                    )}
                                    {/* <div className="login hide-desktop">
                                        <Link title="View Cart" to={TO_CART} className="signup-btn"><i className="fa icon-small fa-shopping-cart"></i><span className=' text-small'>{this.props.cartItems.length ? `(${this.props.cartItems.length})` : ''}</span></Link>
                                    </div> */}
                                    {!this.props.isLoggedIn && (
                                        <div className="login ">
                                            <Link title="Sign In" to={TO_LOGIN} className="login-btn hide-mobile"><i className="fa icon-small fa-sign-in "></i><span className="d-none d-md-inline-block ">Sign In</span></Link>
                                        </div>
                                    )}
                                    {this.props.isLoggedIn && (
                                        <div className="login ">
                                            <Link to={TO_CUSTOMER_ACCOUNT} className="login-btn hide-mobile "><span className="d-none d-md-inline-block ">{this.props.customer.FirstName} {this.props.customer.LastName}</span></Link>
                                            <LogoutButton history={this.props.history} className="signup-btn " text="" />
                                            {/* <Link title="Sign Out" to={TO_LOGOUT} className="signup-btn"><span className="d-none d-md-inline-block"><i className="fa fa-power-off"></i> </span></Link> */}
                                        </div>
                                    )}
                                    
                                    {/* <div className="text-right d-md-none d-block">
                                        <button type="button" data-bs-toggle="collapse" data-target="#navigation" id="check" className="navbar-toggler btn-outline-light hover-bg-transparent"><span className="sr-only">Toggle navigation</span><i className="fa icon-small fa-align-justify"></i></button>
                                        <div id="navigation" className="navbar-collapse collapse">
                                            <ul className="nav navbar-nav ml-auto" id="aligning">
                                                <li data-bs-toggle="collapse" data-target="#navigation" className="nav-item text-change my-1"><NavLink to={TO_STORE}>Store </NavLink></li>
                                                {this.props.isLoggedIn && (
                                                    <li data-bs-toggle="collapse" data-target="#navigation" className="nav-item menu-large text-change my-1"><NavLink activeClassName='active' to={TO_CUSTOMER_ACCOUNT}>Account</NavLink></li>
                                                )}
                                                <li data-bs-toggle="collapse" data-target="#navigation" className="nav-item text-change my-1"><NavLink activeClassName='active' to={TO_FAQ}>HELP</NavLink></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    {/* <ul className="social-custom list-inline">
                                        <li className="list-inline-item"><a href="javascript:void(0)"><i className="fa fa-facebook"></i></a></li>
                                        <li className="list-inline-item"><a href="javascript:void(0)"><i className="fa fa-google-plus"></i></a></li>
                                        <li className="list-inline-item"><a href="javascript:void(0)"><i className="fa fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a href="javascript:void(0)"><i className="fa fa-envelope"></i></a></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="hide-desktop header-search-container">
                            <Search  onChangeHandler={this.handleChange} buttonClick={this.buttonClick}/>

                            {visibleProducts.length > 0 &&  dropdownVisible &&(
                                <div className="container search-dropdown-container" >
                                    <div className="text-center mt-3">
                                        {visibleProducts.map((product) => (
                                            <div
                                            key={product.ProductID}
                                            className="align-items-center justify-content-between"
                                            onClick={() => this.handleCollapsedProductNavigation(product)}
                                            >
                                                <p className="search-dropdown-text">{product.ProductName}</p><hr className="search-dropdown-lineBreak"/>
                                            </div>
                                        ))}
                                        {filteredProducts.length > displayedProducts && (
                                            <a className="dropdown-item" onClick={this.handleLoadMore} style={{color: 'steelblue', marginBottom: '5px'}}>
                                                Load More...
                                            </a>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                
  
                <header className="nav-holder make-sticky hide-mobile">
                    <div id="navbar" role="navigation" className="navbar navbar-expand-lg">
                        <div className="container">
                            <div className="header-border-bottom">
                                <div>
                                    <Link to={TO_HOME} className="navbar-brand home">
                                        <img src="/assets/img/logo2.png" alt="Intrepid logo" className="d-block d-md-inline-block logo-img" />
                                        <img src="/assets/img/logo.png" alt="Intrepid logo" className="hide-desktop home-logo" />
                                        <span className="sr-only">Intrepid - go to homepage</span>
                                    </Link>
                                        {/* <div className="header-padding">
                                            <h1 className="h2">INTREPID CONTROL SYSTEMS <span className="txt-blue">STORE</span></h1>
                                    </div> */}
                                </div>
                                <div className="text-right">
                                    <button type="button" data-bs-toggle="collapse" data-target="#navigation" id="check" className="navbar-toggler btn-template-outlined btn-menu-outline"><span className="sr-only">Toggle navigation</span><i className="fa fa-align-justify"></i></button>
                                    <div id="navigation" className="navbar-collapse collapse">
                                        <ul className="nav navbar-nav ml-auto" id="aligning">
                                            <li className="nav-item"><NavLink to={TO_STORE}>Store </NavLink></li>
                                            {this.props.isLoggedIn && (
                                                <li className="nav-item menu-large"><NavLink activeClassName='active' to={TO_CUSTOMER_ACCOUNT}>My Account/Orders</NavLink></li>
                                            )}
                                            {/* <li className="nav-item menu-large"><NavLink activeClassName='active' to={TO_CART}>View Cart {this.props.cartItems.length ? `(${this.props.cartItems.length})` : ''}</NavLink></li> */}
                                            <li className="nav-item"><NavLink activeClassName='active' to={TO_FAQ}>HELP</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                <div id="search" className="collapse clearfix">
                                    <form role="search" className="navbar-form">
                                        <div className="input-group">
                                            <input type="text" placeholder="Search" className="form-control" /><span className="input-group-btn">
                                                <button type="submit" className="btn btn-template-main"><i className="fa fa-search"></i></button></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* <div id="heading-breadcrumbs">
                    <div className="container">
                        <div className="row d-flex align-items-center flex-wrap">
                            <div className="col-md-7">
                                <h1 className="h2">INTREPID CONTROL SYSTEMS <span className="txt-blue">STORE</span></h1>
                            </div>
                            <div className="col-md-5">
                                <ul className="breadcrumb d-flex justify-content-end">
                                    <li className="breadcrumb-item"><Link to={TO_HOME}>Store </Link></li>
                                    <li className="breadcrumb-item active">INTREPID CONTROL SYSTEMS STORE</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
                <Fragment>
                    {
                        this.state.show &&
                        <div className='off-canvas-overlay' onClick={() => this.showhandle(false)}>
                        </div>
                    }
                    <div className={!this.state.show ? 'off-canvas-sidebar hide-canvas' : 'off-canvas-sidebar canvas-show'}  >
                        <div className='inner-content'>
                            {/* <div>
                                <button onClick={() => this.showhandle(false)} className='overlay-btn'><i className="fa fa-close"></i></button>    
                            </div> */}
                            <div onClick={() => this.showhandle(false)} className='title-products'>
                                {!this.props.isLoggedIn && <Link className="home" title="home" to={TO_HOME} ><i className="fa fa-home fa-2x"></i></Link>}
                                {/* {this.props.isLoggedIn && <LogoutButton history={this.props.history} className="signup-btn" text="" />} */}
                                {this.props.isLoggedIn && <Link className="home" title="home" to={TO_HOME} ><i className="fa fa-home fa-2x"></i></Link>}
                                {/* <Link to={TO_HOME} className=" home d-md-none d-block text-white">
                                    <img src="/assets/img/mobile_icon3.png" alt="Intrepid logo" className="d-md-inline-block logo-img-mobile" />
                                </Link> */}
                                <Link to={TO_HOME} className="home hide-desktop text-white" style={{marginRight: "-10px", marginBottom: "4px"}}>
                                <img src="/assets/img/mobile_icon3.png" alt="Intrepid logo" className="d-md-inline-block logo-img-mobile" />
                                </Link>
                            </div>
                            <div id="navigation-sidebar-top" className="panel-body sidebar-border navbar-collapse collapse">
                                <CategoryTemp categories={this.props.categories}/>
                            </div>
                            {isMobile && (
                                <ul className="nav nav-pills flex-column text-sm category-menu side-bar-ul">
                                    <div className='toggle-menu-btn'>
                                        <h2 className="h4 panel-title">PRODUCTS</h2>
                                    </div>
                                    {this.props.categories && this.props.categories.length > 0 && this.props.categories.map(category => (
                                    <li key={category.id} className="nav-item" onClick={() => {this.showhandle(false)}}>
                                        <NavLink onClick={() => this.handleSelectCategory(category)} activeClassName='active' to={TO_CATEGORY + category.id} className="nav-link d-flex align-items-center justify-content-between">
                                        <span> {category.name} </span>
                                        <span className="badge badge-light">{category.totalProducts}</span>
                                        </NavLink>
                                    </li>
                                    ))}
                                </ul>
                            )}
                            <div>
                            {isMobile && (
                                <div onClick={this.toggleMoreInfo} className='toggle-menu-btn'>
                                    <div>
                                        <h2 className="h4 panel-title" style={{marginBottom:"-2px"}}>MORE INFO</h2>
                                    </div>
                                    <div>
                                    {isMoreInfoOpen ? (
                                        <i className="fa fa-chevron-up"></i>
                                    ) : (
                                        <i className="fa fa-chevron-down"></i>
                                    )}
                                    </div>
                                </div>
                            )}
                                {isMoreInfoOpen && (
                                    <ul className="nav nav-pills flex-column text-sm category-menu side-bar-ul">
                                        {menuItems.map(item => (
                                        <li key={item.key} className="nav-item" onClick={() => { this.showhandle(false) }}>
                                            <NavLink activeClassName='active' to={item.to} className="nav-link d-flex align-items-center justify-content-between">
                                            <span>{item.label}</span>
                                            </NavLink>
                                        </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </Fragment>
            </Fragment>
        );
    }
}


const stateMapHeader = (state) => {
    return {
        isLoggedIn: state.global.isLoggedIn,
        products: state.product.products,
        regionFaild: state.global.regionFaild,
        customer: state.customer.customer.result,
        cartItems: state.cart,
        categories: state.category.categories,
        seoData: state.global.seoData,
    };
};

export default connect(stateMapHeader)(Header);
